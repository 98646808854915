export const HomeBlocks = {
  // COMMON
  HomeBrand: () => import('./HomeBrand.vue'),
  HomeBestSelling: () => import('./HomeBestSelling.vue'),
  HomeLogin: () => import('./HomeLogin.vue'),
  HomeNews: () => import('./HomeNews.vue'),
  HomeNewsDisplay: () => import('./HomeNewsDisplay.vue'),
  HomeRecentProducts: () => import('./HomeRecentProducts.vue'),
  HomeSolutions: () => import('./HomeSolutions.vue'),
  HomeWork: () => import('./HomeWork.vue'),
  HomeTraining: () => import('./Training/HomeTraining.vue'),
};
