<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <section class="hero-slider full-width">
    <h2 v-if="homePageHeading2" v-show="false" class="hero-slider__title">
      {{ homePageHeading2 }}
    </h2>
    <div class="swiper-slider-wrap">
      <div class="swiper" v-swiper="swiperOption" :instance-name="sliderId">
        <div class="swiper-wrapper">
          <template v-if="hpHeroContent">
            <div
              class="swiper-slide"
              v-for="(item, index) in hpHeroContent.data.items"
              :key="`section-${index}`"
            >
              <picture class="hero__image w-full block">
                <source
                  media="(min-width: 767px)"
                  :srcset="
                    item.image.desktop
                      ? item.image.desktop
                      : '/icons/placeholder.svg'
                  "
                />
                <img
                  :src="
                    item.image.mobile
                      ? item.image.mobile
                      : '/icons/placeholder.svg'
                  "
                  class="w-full h-auto"
                  :alt="item.image.alt"
                  @error="handleImageError"
                />
              </picture>
              <div class="hero__wrapper absolute top-0 left-0 w-full h-full">
                <SfLink
                  v-if="item && item.link"
                  :link="localePath(item.link.url)"
                  class="container hero__links h-full text-decoration"
                >
                  <p>{{ $t(item.subtitle) }}</p>
                  <p class="hero__links-item-title" v-html="$t(item.title)" />
                  <label>
                    {{ $t(item.link.label) }}
                    <ArrowWhiteGo />
                  </label>
                </SfLink>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="s-arrow">
        <div
          :id="sliderId + '-button-prev'"
          class="swiper-button-prev button-prev"
          slot="button-prev"
        >
          <SliderLeftArrow />
        </div>
        <div
          :id="sliderId + '-button-next'"
          class="swiper-button-next button-next"
          slot="button-next"
        >
          <SliderRightArrow />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  ref,
  useFetch,
  shallowRef,
  defineComponent,
} from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';
import { contentGetters, useContent } from '@gemini-vsf/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { directive } from 'vue-awesome-swiper';
import 'swiper/swiper.scss';
import {
  SliderLeftArrow,
  SliderRightArrow,
  ArrowWhiteGo,
} from '~/components/General/Icons';

SwiperCore.use([Navigation, Autoplay]);

export default defineComponent({
  name: 'HeroSlider',
  components: {
    SfLink,
    SliderLeftArrow,
    SliderRightArrow,
    ArrowWhiteGo,
  },
  props: {
    sliderData: {
      type: [Array, Object],
    },
    sliderId: {
      type: String,
      default: 'home-hero-slider',
    },
  },
  data() {
    return {
      swiperOption: {
        loop: false,
        observer: true,
        observeParents: true,
        resizeObserver: true,
        navigation: {
          nextEl: `#${this.sliderId}-button-next`,
          prevEl: `#${this.sliderId}-button-prev`,
        },
        autoplay: {
          delay: 5000,
        },
        breakpoints: {
          0: {
            slidesPerView: '1',
            spaceBetween: 0,
            allowTouchMove: true,
          },
          767: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          1200: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        },
      },
    };
  },
  setup() {
    const { locale } = useI18n();
    // Fetching the dynamic data for homepage hero image
    const { blocks, loading, loadBlocks } = useContent(
      `hero-image-${locale.includes('it') ? 'it' : 'en'}`
    );
    const hpHeroSelector = shallowRef(
      `hero-image-${locale.includes('it') ? 'it' : 'en'}`
    );
    const hpHeroContent = ref();
    const homePageHeading2 = ref();

    useFetch(async () => {
      await loadBlocks({ identifiers: [hpHeroSelector.value] });

      try {
        const blockData = contentGetters.getCmsBlockContent(blocks.value);
        hpHeroContent.value = blockData[hpHeroSelector.value];
        homePageHeading2.value = hpHeroContent?.value?.data?.items?.[0]?.title;
      } catch {
        console.warn('Unable to fetch block content.');
      }
    });
    return {
      hpHeroContent,
      loading,
      homePageHeading2,
    };
  },
  methods: {
    handleImageError(event) {
      event.target.src = '/icons/logo.svg';
    },
  },
  directives: {
    swiper: directive,
  },
});
</script>

<style lang="scss">
.hero-slider {
  width: 100%;
  position: relative;
  min-height: 42.5rem;
  background: var(--c-grey);
  @include to-tablet-1500 {
    min-height: 12.5rem;
  }
  &.loading-hero-content {
    min-height: 100vh;
  }
  .sf-skeleton {
    position: absolute;
    height: 100vh;
    width: 100vw;
  }
  .s-arrow {
    justify-content: space-between;
    display: var(--hidden);
    @include for-mobile {
      display: var(--hidden);
    }
    .swiper-button-next,
    .swiper-button-prev {
      border: 0;
      background: none;
      padding: 0 1.25rem;
      cursor: pointer;
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translatey(-50%);
      @include for-mobile {
        display: block;
      }
      &.swiper-button-disabled {
        opacity: 0.5;
      }
      @include for-mobile {
        svg {
          height: 2.188rem;
          width: auto;
        }
      }
    }
    .button-prev {
      left: 1.875rem;
      @include for-mobile {
        left: 0.625rem;
      }
    }
    .button-next {
      right: 1.875rem;
      @include for-mobile {
        right: 0.625rem;
      }
    }
  }
  &:hover {
    .s-arrow {
      display: var(--block);
    }
  }
  .swiper-slide {
    &::before {
      background: var(--c-black);
      content: '';
      height: var(--h-full);
      left: var(--left-0);
      opacity: 0.3;
      position: var(--absolute);
      top: var(--top-0);
      width: var(--w-full);
      z-index: 1;
    }
    .hero__image {
      line-height: 0;
    }
    .hero__wrapper {
      z-index: 1;
      display: var(--flex);
      a {
        color: var(--c-white);
        padding-bottom: 6.75rem;
        display: var(--flex);
        justify-content: flex-end;
        align-items: flex-start;
        flex-direction: var(--flex-col);
        h1 {
          margin-bottom: 2rem;
          max-width: 30.875rem;
          @include to-tablet-1500 {
            font-size: var(--font-size-36);
            line-height: var(--line-height-40);
            margin-bottom: 1.438rem;
          }
          @include to-tablet-max {
            font-size: var(--font-size-28);
            line-height: var(--line-height-32);
            font-family: var(--lato-regular);
            font-weight: normal;
          }
        }
        @include to-tablet-1500 {
          padding-bottom: 2.5rem;
        }
      }
      img {
        line-height: 0;
      }
      p {
        color: var(--c-yellow);
        margin-bottom: 0.75rem;
        @include to-tablet-1500 {
          font-size: var(--font-size-24);
          line-height: var(--line-height-28);
        }
      }
      label {
        display: var(--flex);
        align-items: var(--align-items);
        cursor: var(--cursor-pointer);
        svg,
        img {
          margin-left: 0.5rem;
        }
        &:hover {
          color: var(--c-yellow);
        }
      }
      .hero__links {
        .hero__links-item-title {
          @include for-mobile {
            line-height: var(--line-height-32);
            font-size: var(--font-size-28);
          }
          color: var(--c-white);
          font-size: var(--font-size-64);
          line-height: var(--line-height-68);
          font-weight: var(--font-weight-medium, normal);
          font-family: var(--lato-medium);
          padding: 0;
          margin: 0 0 1.25rem 0;
        }
      }
    }
  }
  .swiper:not(.swiper-container-initialized) {
    .swiper-wrapper {
      display: var(--flex);
      flex-wrap: nowrap;
      overflow: hidden;
      .swiper-slide {
        flex: 0 0 100%;
        width: var(--w-full);
      }
    }
  }
}
</style>
